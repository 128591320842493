import { createContext } from 'react'
import { Feature } from '../providers'

export type FeaturesContextValues = {
  features: Feature[],
}

export type FeaturesContextMethods = {
  updateFeatures: (features: Feature[]) => void,
}

export type FeaturesContextType =
  & FeaturesContextValues
  & FeaturesContextMethods

export const FeaturesContext = createContext<FeaturesContextType>({
  features: [],
  updateFeatures: () => {}
})
