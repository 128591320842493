import React from 'react';
import { List, ListProps, Datagrid, TextField, DateField, BooleanField } from 'react-admin';

export const SeasonsList = (props: ListProps) => {
  return <List {...props} title="List of seasons">
    <Datagrid isRowSelectable={() => false}>
        <TextField source="id" sortable={false} />
        <TextField source="name" />
        <DateField source="startedAt" />
        <DateField source="endAt" />
        <BooleanField source="current" sortable={false} />
    </Datagrid>
  </List>
};