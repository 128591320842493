import { useContext } from 'react'
import { useRefresh, useUpdateMany } from 'react-admin'
import { FeaturesContext } from '../contexts'

export const useSeasonOrgPlan = (
  plan: string,
  ids: number[] = []): [boolean, () => void] => {
  const { features, updateFeatures: update } = useContext(FeaturesContext)
  const refresh = useRefresh()
  // const feature = useFeature('backupSmsSending')
  // const featureId = feature?.id

  // const [updateFeatures, { loading: featuresLoading }] = useMutation({
  //   type: 'update',
  //   resource: 'features',
  //   payload: { id: featureId, data: { enabled } },
  // }, {
  //   onSuccess: ({ data }) => {
  //     const idx = features.findIndex(el => el.id === data.id)
  //     const list = [...features]
  //     list.splice(idx, 1, data)
  //     update(list)
  //     refresh()
  //   },
  //   onFailure: refresh,
  // })

  const [updateSeasonOrganizations, { isLoading: seasOrganizationLoading }] = useUpdateMany(
      'season-organization',
      { ids, data: { plan } },
  {
    onSuccess: refresh,
  })

  return [
    seasOrganizationLoading,
    updateSeasonOrganizations,
  ]
}
