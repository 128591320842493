import { fetchUtils, AuthProvider } from 'react-admin'
import { SessionStorage } from '../storages'
import { UserRole } from "../interfaces";

const apiUrl = process.env.REACT_APP_API_URL
const httpClient = fetchUtils.fetchJson

type AuthResponse = [string, { token: string, expiredAt: number, role: UserRole }]

class KermitAuthProvider implements AuthProvider {
  private storage: SessionStorage

  constructor() {
    this.storage = new SessionStorage()
  }

  public getSession(): [string | null, number | null, UserRole | null] {
    return this.storage.get()
  }

  public async login(params: { username: string, password: string }): Promise<any> {
    const { username: email, password } = params
    if (!email.trim() || !password) Promise.reject()

    try {

      const { json } = await httpClient(
        `${apiUrl}/authorization/sign-in`,
        {
          method: 'post',
          body: JSON.stringify({ email,  password })
        }
      )
      const [err, data] = json as AuthResponse

      if (err) {
        console.error(err)
        return Promise.reject(err)
      }

      const { token, expiredAt, role } = data

      this.storage.set(token, new Date(expiredAt).getTime(), role)

      return Promise.resolve()
    } catch (e) {
      console.error(e)
      return Promise.reject(e)
    }
  }

  public async logout(params: any): Promise<void | false | string> {
    this.storage.clear()
    return Promise.resolve();
  }

  public async checkAuth(params: any): Promise<void> {
    return this.storage.check()
      ? Promise.resolve()
      : Promise.reject()
  }

  public async checkError(error: any) {
    const status = error.status;
    if (status === 401 || status === 403) {
        this.storage.clear()
        return Promise.reject();
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve();
  }

  public async getPermissions(params: any): Promise<UserRole | null> {
    const session = this.getSession();
    return Promise.resolve(session[2]);
  }
}

const authProviderInstance = new KermitAuthProvider()

export const getAuthHeader = (options: RequestInit) => {
  const [authToken] = authProviderInstance.getSession()

  return authToken
    ? {
        ...options,
        headers: new Headers({
          ...options.headers,
          ['x-access-token']: authToken
        })
      }
    : options
}

export const AppAuthProvider = authProviderInstance
