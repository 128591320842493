import React from 'react';
import { Button, useDataProvider, useRecordContext, useResourceContext } from 'react-admin';

export const LoginButton: React.FC = () => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const resource = useResourceContext()

  const login = async () => {
    const { data: link } = await (dataProvider as any).getAuthLink(resource, record.id);
    if (link) {
      window.open(link, '_blank')?.focus();
    }
  }

  return (
    <Button label="Log In" onClick={login} />
  );
}
