import React, { FC } from 'react';
import {
  List,
  ListProps,
  Datagrid,
  TextField,
  Filter as AdminFilter,
  FilterProps,
  TextInput,
  EmailField,
  ShowButton,
  NumberInput,
  SelectInput,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { CopyRegistrantAuthLinkButton, LoginButton } from './components';
import { ListActions } from '../../common/list-actions';
import { currentSeason, seasons } from '../../filters/seasonFilter'

const DEFAULT_FILTERS = {
    seasonName: currentSeason
}

const useStyles = makeStyles({
  participantInput: {
    '& input::placeholder': {
      fontSize: '12px',
    }
  },
});

const Filter: FC<Omit<FilterProps, 'children'>> = (props) => {
  const classes = useStyles()
  return (
    <AdminFilter {...props}>
      <SelectInput label="Season name" optionText="id" source="seasonName" choices={seasons} alwaysOn />
      <TextInput label="Organization ID" source="midasId" alwaysOn />
      <NumberInput label="Phone" source="phone" type="tel" alwaysOn />
      <TextInput label="Email" source="email" type="email" alwaysOn />
      <TextInput
        label="Participant"
        placeholder="E.g. code, first name or last name"
        source="participant"
        alwaysOn
        className={classes.participantInput}
      />
    </AdminFilter>
  )
};

export const RegistrantList = (props: ListProps) => {
  return <List {...props}
    actions={<ListActions/>}
    empty={false}
    filterDefaultValues={DEFAULT_FILTERS}
    title="List of season organizations"
    filters={<Filter />}>
    <Datagrid
      isRowSelectable={() => false}
    >
        <TextField source="id" sortable={false}  />
        <TextField source="user.phone" label="Phone" sortable={false} />
        <EmailField source="user.email" label="Email" />
        <TextField source="participantsCount" label="Participants count" />
        <ShowButton />
        <LoginButton />
        <CopyRegistrantAuthLinkButton />
    </Datagrid>
  </List>
};
