import { UserRole } from "../interfaces";

const authKey = 'kermit-admin-token'
const authExpiredAtKey = 'kermit-admin-token-expired-at'
const authRole = 'kermit-admin-role'

export class SessionStorage {
  private timersDictionary: Map<string, NodeJS.Timeout>
  private sessionDictionary: Map<string, string>

  constructor() {
    this.timersDictionary = new Map()
    this.sessionDictionary = new Map()

    const [authToken, expiredAt, role] = this.get()
    if (authToken && expiredAt && role) this.set(authToken, expiredAt, role)
  }

  public get(): [string | null, number | null, UserRole | null] {
    const authToken = this.sessionDictionary.get(authKey) || localStorage.getItem(authKey)
    const expiredAt = this.sessionDictionary.get(authExpiredAtKey) || localStorage.getItem(authExpiredAtKey)
    const role = (this.sessionDictionary.get(authRole) || localStorage.getItem(authRole)) as UserRole

    return [authToken, expiredAt ? parseInt(expiredAt, 10) : null, role]
  }

  public set(token: string, expiredAt: number, role: UserRole): void {
    this.clear()

    localStorage.setItem(authKey, token)
    localStorage.setItem(authExpiredAtKey, expiredAt.toString())
    localStorage.setItem(authRole, role)

    this.sessionDictionary.set(authKey, token)
    this.sessionDictionary.set(authExpiredAtKey, expiredAt.toString())
    this.sessionDictionary.set(authRole, role)

    const timeOut = new Date(expiredAt).getTime() - Date.now()

    const timerID = setTimeout(() => {
      this.clear()
    }, timeOut > 0 ? timeOut : 0)

    this.timersDictionary.set(authKey, timerID)
  }

  public check(): boolean {
    const [authToken, expiredAt, _] = this.get()

    if (!authToken || !expiredAt) return false

    const expiredAtDate = new Date(expiredAt)

    if (new Date() > expiredAtDate) {
      this.clear()
      return false
    }
    return true
  }

  clear(): void {
    const timerID = this.timersDictionary.get(authKey)

    if (timerID) {
      clearTimeout(timerID)
      this.timersDictionary.delete(authKey)
    }

    localStorage.removeItem(authKey)
    localStorage.removeItem(authExpiredAtKey)
    localStorage.removeItem(authRole)
    this.sessionDictionary.delete(authKey)
    this.sessionDictionary.delete(authExpiredAtKey)
    this.sessionDictionary.delete(authRole)
  }

}
