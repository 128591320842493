import React, { ReactElement, useState } from 'react'
import { Button, useNotify } from 'react-admin'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'

export const CopyContentButton = (props: CopyContentButtonProps) => {
    const {
        icon = defaultIcon,
        label = 'Copy',
        content,
        message = 'Copied to clipboard',
        ...rest
    } = props

    const [contentString, setContentString] = useState<string | null>(null)

    const notify = useNotify();
    const handleClick = async () => {
        if (content) {
            if (!contentString) {
                const _contentString = typeof content === 'string' ? content : await content()
                setContentString(_contentString)
                copyContent(_contentString)
            } else {
                copyContent(contentString)
            }
        }
    }

    const copyContent = (content: string | null) => {
        if (content) {
            _copyContent(content)
            if (message) {
                notify(message, { type: 'info' });
            }
        }
    }

    return (
        <Button
            label={label}
            onClick={handleClick}
        >
            { icon }
        </Button>
    )
}

type CopyButtonContent = string | (() => string | null) | (() => Promise<string | null>)

interface Props {
    icon?: ReactElement
    label?: string
    content: CopyButtonContent
    message?: string
}

const defaultIcon = <ContentCopyIcon />

export type CopyContentButtonProps = Props

const _copyContent = (content: string) => {
    if (navigator.clipboard) {
        navigator.clipboard.writeText(content)
    } else {
        const input = document.createElement('input')
        input.value = content
        input.style.display = 'hidden'
        document.body.appendChild(input)
        input.select()
        document.execCommand('copy')
        document.body.removeChild(input)
    }
}
