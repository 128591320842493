export const seasons = [
  { id: '2021F' },
  { id: '2022S' },
  { id: '2022F' },
  { id: '2023S' },
  { id: '2023F' },
  { id: '2024S' },
  { id: '2024F' },
  { id: '2025S' },
];

export const currentSeason = '2025S'
