import React, { FC } from 'react';
import {
  List,
  ListProps,
  Datagrid,
  TextField,
  DateField,
  downloadCSV,
  Filter as AdminFilter,
  FilterProps,
  TextInput,
  SelectInput
} from 'react-admin';
import jsonExport from 'jsonexport/dist'
import { makeStyles } from '@material-ui/core/styles';
import omit from 'lodash.omit';
import { Visit } from '../../interfaces';
import { ListActions } from '../../common/list-actions';
import { currentSeason, seasons } from '../../filters/seasonFilter'

const DEFAULT_FILTERS = { seasonName: currentSeason }

type ExtendedVisit = Omit<Visit, 'additionalParams'> & {
  registrantId: number | null
  participantCodes: string[] | null
}

const useStyles = makeStyles({
  urlInput: {
    'word-break': 'break-all'
  },
});

const exporter = (visits: Visit[]) => {
  const visitsForExport = visits.map((visit): ExtendedVisit => ({
    ...omit(visit, 'additionalParams'),
    registrantId: visit.additionalParams ? visit.additionalParams.registrantId : null,
    participantCodes: visit.additionalParams ? visit.additionalParams.participantCodes : null,
  }));
  jsonExport(visitsForExport, {
    headers: ['id', 'fingerprint', 'visitTime', 'url', 'referer', 'midasId', 'seasonName', 'userAgent', 'registrantId', 'participantCodes']
  }, (err, csv) => {
    if (err) console.error(err);
    downloadCSV(csv, 'visits');
  });
}

const Filter: FC<Omit<FilterProps, 'children'>> = (props) => {
  return (
    <AdminFilter {...props}>
      <SelectInput label="Season name" optionText="id" source="seasonName" choices={seasons} alwaysOn />
      <TextInput label="Organization ID" source="midasId" alwaysOn />
    </AdminFilter>
  )
};

export const VisitsList = (props: ListProps) => {
  const classes = useStyles()
  return (
    <List
      {...props}
      title="List of visits"
      exporter={exporter}
      filterDefaultValues={DEFAULT_FILTERS}
      filters={<Filter />}
      actions={<ListActions/>}
    >
      <Datagrid isRowSelectable={() => false}>
        <TextField source="id" sortable={false} />
        <TextField source="fingerprint" />
        <TextField source="url" className={classes.urlInput} />
        <TextField source="referer" className={classes.urlInput} />
        <TextField source="midasId" />
        <TextField source="seasonName" />
        <TextField source="userAgent" />
        <DateField source="visitTime" />
      </Datagrid>
    </List>
  )
};
