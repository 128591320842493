import React from 'react'
import { SelectInput } from 'react-admin'

export const UserRoleSelectInput = () => {
    return (
        <SelectInput source="role" choices={[
            { id: 'admin', name: 'Employee' },
            { id: 'super_admin', name: 'Super Admin' },
        ]} />

    )
}
