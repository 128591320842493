import React, { FC, useState, useEffect } from 'react'
import { useDataProvider } from 'react-admin'
import { FeaturesContext, FeaturesContextType } from '../contexts'
import { Feature } from '../providers'

export const FeaturesPayload: FC = ({ children }) => {
  const provider = useDataProvider()
  const updateFeatures = (features: Feature[]) => {
    setContext({ ...context, features })
  }

  const [context, setContext] = useState<FeaturesContextType>({
    features: [],
    updateFeatures
  })

  useEffect(() => {
    provider.getList<Feature>('features', {
      filter: {},
      pagination: { page: 1, perPage: 10000 },
      sort: { field: 'id', order: 'DESC' }
    })
    .then(({ data }) => {
      updateFeatures(data)
    })
  }, [provider])

  return <FeaturesContext.Provider value={context}>
    {children}
  </FeaturesContext.Provider>
}
