import React from 'react';
import { Datagrid, EmailField, List, ListProps, EditButton, TextField } from "react-admin";
import { BooleanField } from "ra-ui-materialui";

const EditUserButton = () => (
    <EditButton />
)

export const UsersList = (props: ListProps) => {
    return <List {...props} title="Users">
        <Datagrid
            isRowSelectable={() => false}
        >
            <TextField source="id" sortable={false}  />
            <EmailField source="email" label="Email" />
            <TextField source="role" label="Role"  sortable={false} />
            <BooleanField source="active" label="Active"  sortable={false} />
            <EditUserButton />
        </Datagrid>
    </List>
};
