import React from 'react'
import {
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  FunctionField,
  DateField,
  Datagrid,
  ReferenceManyField,
  Pagination
} from 'react-admin'

const Empty = ({ resource }: any) => {
  return <p>Registrant has no {resource}</p>
}

const ReferenceTable = (props: any) => {
  const { id: registrantId, referenceTo, children } = props
  const filter = { registrantId }
  return <ReferenceManyField
    pagination={<Pagination />}
    reference={referenceTo}
    target="registrantId"
    label={false}
    filter={filter}>
    <Datagrid empty={<Empty resource={referenceTo} />}>
      {children}
    </Datagrid>
  </ReferenceManyField>
}

const ParticipantsList = (props: any) =>
  <ReferenceTable {...props} referenceTo="participant">
    <TextField source="id" />
    <FunctionField
      label="Full Name"
      render={(record: any) => `${record.firstName} ${record.lastName}` } />
    <TextField label="Grade" source="teacher.grade.grade" />
    <TextField source="code" />
    <DateField source="createdAt" />
  </ReferenceTable>

const NotificationList = (props: any) =>
  <ReferenceTable {...props} referenceTo="notification">
    <TextField source="id" />
    <TextField source="type" />
    <DateField source="createdAt" />
  </ReferenceTable>

export const RegistrantsShow = (props: any) => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="Participants">
        <ParticipantsList {...props} />
      </Tab>
      <Tab label="Notification" path="notifications">
        <NotificationList {...props} />
      </Tab>
    </TabbedShowLayout>
  </Show>
);
