import React from 'react'
import {
    Edit,
    Toolbar,
    SimpleForm,
    SaveButton,
    TextInput,
    useRecordContext,
    BooleanInput,
    PasswordInput,
} from 'react-admin'
import { UserRoleSelectInput } from './components'
import { userEditValidation } from './validation'

const UserEditTitle = () => {
    const record = useRecordContext()
    return (
        <span>User { record ? `${record.email}` : '' }</span>
    )
}

const UserEditToolbar = () => {
    return (
        <Toolbar>
            <SaveButton />
        </Toolbar>
    )
}

export const UserEdit = () => {
    return (
        <Edit title={<UserEditTitle />} mutationMode="pessimistic">
            <SimpleForm toolbar={<UserEditToolbar />} validate={userEditValidation}>
                <TextInput label="Email" source="email" type="email"/>
                <UserRoleSelectInput />
                <PasswordInput label="Password" source="password"/>
                <PasswordInput label="Password Confirmation" source="password_confirmation"/>
                <BooleanInput label="Active" source="active"/>
            </SimpleForm>
        </Edit>
        )
}
