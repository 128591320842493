import { FieldValues } from 'react-hook-form/dist/types/fields'
import { AppDataProvider } from '../../providers'

export const userCreateValidation = async (values: FieldValues): Promise<FieldValues> => {
    const errors: FieldValues = {}
    if (!values.email) {
        errors.email = 'ra.validation.required'
    } else {
        const isEmailUnique = await AppDataProvider.checkEmailIsUnique(values.email)
        if (!isEmailUnique) {
            errors.email = 'Email already used'
        }
    }
    if (!values.role) {
        errors.role = 'ra.validation.required'
    }
    if (values.password) {
        if (values.password.length < 8) {
            errors.password = 'Password must be at least 8 characters'
        }
        if (values.password !== values.password_confirmation) {
            errors.password = 'Password and Password Confirmation doesn\'t match'
        }
    } else {
        errors.password = 'ra.validation.required'
    }
    return errors
}

export const userEditValidation = async (values: FieldValues): Promise<FieldValues> => {
    const errors: FieldValues = {}
    if (!values.email) {
        errors.email = 'ra.validation.required'
    } else {
        const isEmailUnique = await AppDataProvider.checkEmailIsUnique(values.email, values.id)
        if (!isEmailUnique) {
            errors.email = 'Email already used'
        }
    }
    if (!values.role) {
        errors.role = 'ra.validation.required'
    }
    if (values.password) {
        if (values.password.length < 8) {
            errors.password = 'Password must be at least 8 characters'
        }
        if (values.password !== values.password_confirmation) {
            errors.password = 'Password and Password Confirmation doesn\'t match'
        }
    }
    return errors
}
