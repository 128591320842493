import React from 'react'
import { Create, SimpleForm, TextInput, PasswordInput } from 'react-admin'
import { UserRole } from "../../interfaces";
import { UserRoleSelectInput } from './components'
import { userCreateValidation } from './validation'

const UserCreateTitle = () => {
    return (
        <span>New User</span>
    )
}

export const UserCreate = () => {
    return (
        <Create title={<UserCreateTitle />}>
            <SimpleForm defaultValues={{ role: UserRole.ADMIN, active: true }} validate={userCreateValidation}>
                <TextInput label="Email" source="email" type="email"/>
                <UserRoleSelectInput />
                <PasswordInput label="Password" source="password"/>
                <PasswordInput label="Password Confirmation" source="password_confirmation"/>
            </SimpleForm>
        </Create>
    )
}
