import {
  fetchUtils,
  DataProvider,
  GetListParams,
  GetOneParams,
  GetManyReferenceParams,
  UpdateParams,
  UpdateManyParams,
  CreateParams,
} from 'react-admin'
import { getAuthHeader } from './authProvider'

const apiUrl = process.env.REACT_APP_API_URL
const httpClient = fetchUtils.fetchJson

export interface Feature {
  id: number
  name: string
  enabled: boolean
}

class KermitDataProvider implements DataProvider {
  public getList(resource: string, params: GetListParams) {
    const { page, perPage } = params.pagination
    const { field, order } = params.sort
    const { filter } = params

    const filterList: string[] = []
    Object.keys(filter)
      .filter(key => ['sort', 'offset', 'limit'].indexOf(key) === -1)
      .forEach((key) => { filterList.push(`${key}=${filter[key]}`) })

    const query = [
      `sort=${field}:${order}`,
      `offset=${(page - 1) * perPage}`,
      `limit=${page * perPage}`,
      ...filterList,
    ];
    const url = `${apiUrl}/${resource}/list?${query.join('&')}`
    return httpClient(url, getAuthHeader({})).then(({ json }) => {
      const [, { rows: data, count: total }] = json
      return {
        data,
        total
      }
    })
  }

  public getOne(resource: string, params: GetOneParams) {
    const { id } = params
    const url = `${apiUrl}/${resource}/${id}`
    return httpClient(url, getAuthHeader({})).then(({ json }) => {
      const [, data] = json
      return { data }
    })
  }

  public getManyReference(resource: string, params: GetManyReferenceParams) {
    const { target, id, ...rest } = params
    const advancedParams = { ...rest, filter: {
      ...params.filter,
      [target]: id
    }}
    return this.getList(resource, advancedParams)
  }
  // unimplemented methods
  // TODO - implement methods when it will necessity
  public update<T>(resource: string, params: UpdateParams) {
    const { id, data } = params

    return this.updateMany(resource, { ids: [id], data })
      .then(({ data: result }) => {
        const [data] = result
        return { data } as { data: T }
      })
  }

  public getMany() {
    return Promise.resolve({ data : [] });
  }

  public updateMany(resource: string, params: UpdateManyParams) {
    const { ids, data } = params
    const url = `${apiUrl}/${resource}`
    const body = JSON.stringify({ ids, data })
    return httpClient(url, getAuthHeader({ method: 'PUT', body })).then(({ json }) => {
      const [, data] = json
      return { data }
    });
  }

  public create<T>(resource: string, params: CreateParams) {
    const { data } = params
    const url = `${apiUrl}/${resource}`
    const body = JSON.stringify(data)
    return httpClient(url, getAuthHeader({ method: 'POST', body })).then(({ json }) => {
      const [, data] = json
      return { data }
    });
  }

  public delete<T>() {
    return Promise.resolve({ data: {} as T });
  }

  public deleteMany() {
    return Promise.resolve({ data: [] });
  }

  public getAuthLink(resource: string, registrantId: number) {
    if (resource !== 'registrant') return { data: null }

    const url = `${apiUrl}/${resource}/auth-link?registrantId=${registrantId}`
    return httpClient(url, getAuthHeader({})).then(({ json }) => {
      const [, data] = json;
      return { data }
    })
  }

  public checkEmailIsUnique(email: string, id?: number) {
    const url = `${apiUrl}/user/email-check`
    const body = JSON.stringify({ email, id })
    return httpClient(url, getAuthHeader({ method: 'POST', body})).then(({ json }) => {
      const [, data] = json;
      return data
    })
  }
}

export const AppDataProvider = new KermitDataProvider()
