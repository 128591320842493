import { CopyContentButton } from '../../../components/CopyContentButton'
import { useDataProvider, useRecordContext, useResourceContext } from 'react-admin'
import { useCallback } from "react";

export const CopyRegistrantAuthLinkButton = () => {
    const dataProvider = useDataProvider()
    const record = useRecordContext()
    const resource = useResourceContext()

    const getAuthLink = useCallback(async (): Promise<string | null> => {
        const { data: authLink } = await (dataProvider as any).getAuthLink(resource, record.id)
        return authLink
    }, [dataProvider, resource, record.id])

    return (
        <CopyContentButton label={''} content={getAuthLink}/>
    )
}
