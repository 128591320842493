import React, { FC } from 'react';
import {
  List,
  ListProps,
  Datagrid,
  TextField,
  DateField,
  Filter,
  FilterProps,
  TextInput,
  TopToolbar,
  ExportButton,
  Button,
  useListContext,
  SelectInput,
} from 'react-admin';
import { useSeasonOrgPlan } from '../../hooks'
import { FeaturesPayload } from '../../components';
import { currentSeason, seasons } from '../../filters/seasonFilter'

const DEFAULT_FILTERS = {
  seasonName: currentSeason,
  plan: 'c'
}

// const PLANS = [
//   { id: 'a', name: 'Plan A' },
//   { id: 'b', name: 'Plan B' },
//   { id: 'c', name: 'Plan C' }
// ]

const PostFilter: FC<Omit<FilterProps, 'children'>> = (props) => (
  <Filter {...props}>
    <SelectInput label="Season name" optionText="id" source="seasonName" choices={seasons} alwaysOn />
    <TextInput label="Organization ID" source="midasId" alwaysOn />
    <TextInput label="Org Plan" source="plan" alwaysOn />
    {/*<SelectArrayInput label="Org Plan" source="plan" choices={PLANS} alwaysOn />*/}
  </Filter>
);

const SeasonOrganizationsActions = (props: any) => {
  return <TopToolbar>
    <ExportButton maxResults={ 100000 } />
  </TopToolbar>
}

const SeasonOrganizationsBulkActions = (props: any) => {
  const { selectedIds } = props
  const { data, onSelect } = useListContext()

  const [loadingA, setPlanA] = useSeasonOrgPlan('a', selectedIds)
  const [loadingB, setPlanB] = useSeasonOrgPlan('b', selectedIds)
  const [loadingC, setPlanC] = useSeasonOrgPlan('c', selectedIds)

  const update = (plan: 'a' | 'b' | 'c') => () => {
    switch (plan) {
      case 'a':
        setPlanA()
            break
      case 'b':
        setPlanB()
            break
      case 'c':
        setPlanC()
            break
    }
    onSelect([])
  }
  return <>
    <Button
      disabled={loadingA || loadingB || loadingC}
      label="Set plan A"
      onClick={update('a')}
    />
    <Button
      disabled={loadingA || loadingB || loadingC}
      label="Set plan B"
      onClick={update('b')}
    />
    <Button
      disabled={loadingA || loadingB || loadingC}
      label="Set plan C"
      onClick={update('c')}
    />
  </>
}

export const SeasonOrganizationsList = (props: ListProps) => {
  return <FeaturesPayload>
    <List {...props}
      empty={false}
      filterDefaultValues={DEFAULT_FILTERS}
      title="List of season organizations"
      filters={<PostFilter />}
      actions={<SeasonOrganizationsActions/>}
      bulkActionButtons={<SeasonOrganizationsBulkActions />}>
      <Datagrid>
        <TextField
          source="organization.midasId"
          label="Midas Id"
          sortable={false} />
        <TextField source="organization.name" label="Name" />
        <TextField source="organization.state" label="State" />
        <TextField source="plan" />
        <DateField source="startedAt" />
        <DateField source="endAt" />
      </Datagrid>
    </List>
  </FeaturesPayload>
};
