import React from 'react';
import './App.css';
import { Admin, Resource } from 'react-admin';
import { AppAuthProvider, AppDataProvider } from './providers';
import { UserRole } from "./interfaces";
import {
    SeasonsList,
    SeasonOrganizationsList,
    RegistrantList,
    RegistrantsShow,
    VisitsList,
    UsersList,
    UserCreate,
    UserEdit,
} from './resources';

const App = () => {
    return (
        <Admin dataProvider={AppDataProvider} authProvider={AppAuthProvider}>
            {permissions => (
                <>
                    <Resource name="season" list={SeasonsList}/>
                    <Resource name="season-organization" list={SeasonOrganizationsList}/>
                    <Resource name="registrant" list={RegistrantList} show={RegistrantsShow}/>
                    <Resource name="visit" list={VisitsList}/>
                    <Resource name="participant"/>
                    <Resource name="notification"/>
                    {permissions === UserRole.SUPER_ADMIN && (
                        <Resource name="user" list={UsersList} create={UserCreate} edit={UserEdit} />
                    )}
                    {/*<Resource name="features" />*/}
                </>
            )}
        </Admin>
        )
}

export default App;
